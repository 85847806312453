export default {
  id: {
    type: Number,
    visibility: false,
  },
  name: {
    label: 'Nombre',
    type: String,
  },
  color: {
    label: 'Color',
    type: String,
    color: true,
  },
  geofenceRadio: {
    label: 'Radio de Geocerca',
    type: Number,
    default: 500,
  },
  // devices: {
  //   label: 'Dispositivos',
  //   type: Array,
  //   optional: true,
  //   title: (item) => item.name,
  //   subtitle: (item) => item.driverName,
  // },
  // 'devices.$': {
  //   type: {
  //     id: {
  //       label: 'Dispositivos',
  //       type: Number,
  //       model: 'devices',
  //       autocomplete: false,
  //       changeOnLoad: false,
  //       onChange(value, fields) {
  //         if (value) {
  //           const item = fields.id.getOption()
  //           fields.name.value = item.name;
  //           fields.driverId.value = item.attributes?.conductor;
  //         } else {
  //           fields.name.value = '';
  //         }
  //       }
  //     },
  //     name: {
  //       label: 'Nombre',
  //       type: String,
  //       readonly: true,
  //       visibility: false,
  //     },
  //     driverId: {
  //       label: 'Conductor',
  //       type: String,
  //       model: 'v2/drivers',
  //       autocomplete: false,
  //       optional: true,
  //       changeOnLoad: false,
  //       onChange: (value, fields) => {
  //         console.log({ value })
  //         if (value) {
  //           const item = fields.driverId.getOption()
  //           if (!item) {
  //             fields.driverId.value = undefined;
  //           }
  //           fields.driverName.value = item.name;
  //         } else {
  //           fields.driverName.value = '';
  //         }
  //       }
  //     },
  //     driverName: {
  //       label: 'Nombre del conductor',
  //       type: String,
  //       visibility: false,
  //       readonly: true,
  //       optional: true,
  //     },
  //   },
  // },
  route: {
    label: 'Ruta',
    type: Array,
    route: true,
    optional: true,
    blackbox: true,
  },
  'route.$': {
    type: {
      latitude: {
        label: 'Latitude',
        type: Number,
      },
      longitude: {
        label: 'Longitude',
        type: Number,
      },
    },
  },
  // attributes: {
  //   label: 'Atributos',
  //   type: Object,
  //   optional: true,
  //   attributes: true
  // }
};
