<template>
  <kalan-dialog
    title="Dispositivos en la ruta"
    :subtitle="route.name"
    height="650px"
    :actions="actions"
  >
    <v-card class="mx-auto elevation-0" height="100%">
      <kalan-list
        :loading="loading"
        :getTitle="getTitle"
        :getSubTitle="getSubTitle"
        :items="items"
        :search="search"
        :actions="listActions"
      >
      </kalan-list>
    </v-card>
  </kalan-dialog>
</template>

<script>
import ApiService from '@/util/api.service';
import Dialog from '@/components/dialog';
import Form from '@/components/form';

export default {
  props: {
    route: {
      type: Object,
      required: true,
    },
  },
  components: {
    'kalan-list': () => import('@/components/modulo/List.vue'),
    'kalan-dialog': () => import('@/components/dialog/Dialog.vue'),
  },
  data() {
    return {
      items: [],
      loading: false,
      search: '',
      actions: [
        {
          help: 'Agregar dispositivo',
          icon: 'mdi-plus',
          color: 'secondary',
          action: () => {
            const form = new Form({
              schema: {
                id: {
                  label: 'Dispositivos',
                  type: Number,
                  model: 'devices',
                  autocomplete: false,
                  changeOnLoad: false,
                  onChange(value, fields) {
                    if (value) {
                      const item = fields.id.getOption();
                      fields.name.value = item.name;
                      fields.driverId.value = item.attributes?.conductor;
                    } else {
                      fields.name.value = '';
                    }
                  },
                },
                name: {
                  label: 'Nombre',
                  type: String,
                  readonly: true,
                  visibility: false,
                },
                driverId: {
                  label: 'Conductor',
                  type: String,
                  model: 'v2/drivers',
                  autocomplete: false,
                  optional: true,
                  changeOnLoad: false,
                  onChange: (value, fields) => {
                    if (value) {
                      const item = fields.driverId.getOption();
                      if (!item) {
                        fields.driverId.value = undefined;
                      }
                      fields.driverName.value = item.name;
                    } else {
                      fields.driverName.value = '';
                    }
                  },
                },
                driverName: {
                  label: 'Nombre del conductor',
                  type: String,
                  visibility: false,
                  readonly: true,
                  optional: true,
                },
              },
            });
            const dialog = new Dialog({
              title: 'Agregar dispositivo a la ruta',
              subtitle: this.route.name,
              actions: [
                {
                  help: 'Guardar',
                  icon: 'mdi-content-save',
                  color: 'secondary',
                  action: async () => {
                    if (form.hasErrors()) {
                      return;
                    }
                    window.VMA.loading(true);
                    ApiService({
                      url: `/v2/routes/addDevice`,
                      method: 'post',
                      data: {
                        ...form.getItem(),
                        routeId: this.route.id,
                      },
                    })
                      .then((resp) => {
                        dialog.close();
                        this.items = resp.devices;
                        this.route.devices = resp.devices;
                      })
                      .catch((err) => {
                        console.error({ err });
                        window.VMA.showError({ title: 'Error al guardar' });
                      })
                      .finally(() => {
                        window.VMA.loading(false);
                      });
                  },
                },
              ],
            });
            dialog.open();
            dialog.append(form);
          },
        },
      ],
      listActions: [
        {
          help: 'Eliminar',
          icon: 'mdi-delete',
          color: 'red',
          action: (item) => {
            window.VMA.loading(true);
            ApiService({
              url: `/v2/routes/removeDevice`,
              method: 'post',
              data: {
                ...item,
                routeId: this.route.id,
              },
            })
              .then((resp) => {
                this.items = resp.devices;
                this.route.devices = resp.devices;
              })
              .catch((err) => {
                console.error({ err });
                window.VMA.showError({ title: 'Error al guardar' });
              })
              .finally(() => {
                window.VMA.loading(false);
              });
          },
        },
      ],
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    getTitle: (item) => item.name,
    getSubTitle: (item) => item.driverName,
    load() {
      this.items = this.route.devices;
      // this.loading = true;
      // ApiService({
      //   url: `/v2/routes/devices/${this.route.id}`,
      //   method: 'get',
      // })
      //   .then((resp) => {
      //     this.items = resp.devices;
      //     this.loading = false;
      //   })
      //   .catch((err) => {
      //     this.loading = false;
      //   });
    },
  },
};
</script>
